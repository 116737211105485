var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "bookmarks-wrapper",
      attrs: { "aria-label": _vm.$tr("bookmarksSection") },
    },
    _vm._l(_vm.outline, function (item, index) {
      return _c("BookmarkItem", {
        key: index,
        attrs: {
          item: item,
          goToDestination: _vm.goToDestination,
          focusDestPage: _vm.focusDestPage,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }