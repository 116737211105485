var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "bookmark-item" }, [
    _c("div", { staticClass: "bookmark-item-title-container" }, [
      _vm.item.items && _vm.item.items.length > 0
        ? _c(
            "span",
            {
              staticClass: "dropdown-icon-container",
              attrs: {
                tabindex: "0",
                role: "button",
                "aria-label": _vm.$tr("expand"),
                "aria-controls": "pdf-container",
              },
              on: {
                click: _vm.toggleExpanded,
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.toggleExpanded.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    ) {
                      return null
                    }
                    return _vm.toggleExpanded.apply(null, arguments)
                  },
                ],
              },
            },
            [
              _c("KIcon", {
                staticClass: "dropdown-icon",
                class: { expanded: _vm.expanded },
                attrs: { icon: "chevronRight" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "bookmark-item-title",
          attrs: { tabindex: "0", role: "button" },
          on: {
            click: function ($event) {
              return _vm.goToDestination(_vm.item.dest)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (!$event.shiftKey) {
                  return null
                }
                return _vm.focusDestPage(_vm.item.dest, $event)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.goToDestination(_vm.item.dest)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                return _vm.goToDestination(_vm.item.dest)
              },
            ],
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.item.title) + "\n    ")]
      ),
    ]),
    _vm.expanded
      ? _c(
          "ul",
          { staticClass: "bookmark-children" },
          _vm._l(_vm.item.items, function (child, index) {
            return _c("BookmarkItem", {
              key: index,
              attrs: {
                item: child,
                goToDestination: _vm.goToDestination,
                focusDestPage: _vm.focusDestPage,
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }