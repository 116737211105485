var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.handleVisibilityChange,
          expression: "handleVisibilityChange",
        },
      ],
      staticClass: "vue-recycle-scroller",
      class:
        ((_obj = {
          ready: _vm.ready,
          "page-mode": _vm.pageMode,
        }),
        (_obj["direction-" + _vm.direction] = true),
        _obj),
      on: {
        "&scroll": function ($event) {
          return _vm.handleScroll.apply(null, arguments)
        },
      },
    },
    [
      _vm.$slots.before
        ? _c(
            "div",
            { ref: "before", staticClass: "vue-recycle-scroller-slot" },
            [_vm._t("before")],
            2
          )
        : _vm._e(),
      _c(
        _vm.listTag,
        {
          ref: "wrapper",
          tag: "component",
          staticClass: "vue-recycle-scroller-item-wrapper",
          class: _vm.listClass,
          style:
            ((_obj$1 = {}),
            (_obj$1[_vm.direction === "vertical" ? "minHeight" : "minWidth"] =
              _vm.totalSize + "px"),
            _obj$1),
        },
        [
          _vm._l(_vm.pool, function (view) {
            return _c(
              _vm.itemTag,
              _vm._g(
                {
                  key: view.nr.id,
                  tag: "component",
                  staticClass: "vue-recycle-scroller-item-view",
                  class: [
                    _vm.itemClass,
                    {
                      hover: !_vm.skipHover && _vm.hoverKey === view.nr.key,
                    },
                  ],
                  style: _vm.ready
                    ? {
                        transform:
                          "translate" +
                          (_vm.direction === "vertical" ? "Y" : "X") +
                          "(" +
                          view.position +
                          "px) " +
                          "translate" +
                          (_vm.direction === "vertical" ? "X" : "Y") +
                          "(" +
                          view.offset +
                          "px)",
                        width: _vm.gridItems
                          ? (_vm.direction === "vertical"
                              ? _vm.itemSecondarySize || _vm.itemSize
                              : _vm.itemSize) + "px"
                          : undefined,
                        height: _vm.gridItems
                          ? (_vm.direction === "horizontal"
                              ? _vm.itemSecondarySize || _vm.itemSize
                              : _vm.itemSize) + "px"
                          : undefined,
                      }
                    : null,
                },
                _vm.skipHover
                  ? {}
                  : {
                      mouseenter: function () {
                        _vm.hoverKey = view.nr.key
                      },
                      mouseleave: function () {
                        _vm.hoverKey = null
                      },
                    }
              ),
              [
                _vm._t("default", null, {
                  item: view.item,
                  index: view.nr.index,
                  active: view.nr.used,
                }),
              ],
              2
            )
          }),
          _vm._t("empty"),
        ],
        2
      ),
      _vm.$slots.after
        ? _c(
            "div",
            { ref: "after", staticClass: "vue-recycle-scroller-slot" },
            [_vm._t("after")],
            2
          )
        : _vm._e(),
      _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }